<template>
  <b-sidebar
    id="sidebar-task-handler"
    sidebar-class="sidebar-lg"
    :visible="isTaskHandlerSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('closed')"
  >
    <div class="d-inline-flex w-100 justify-content-end mb-0">
      <b-button variant="flat-primary" class="mb-0" @click="linkClicked">
        <feather-icon icon="ExternalLinkIcon" class="mr-25" />
        Open Test Case
      </b-button>
    </div>
    <slot />
  </b-sidebar>
</template>

<script>
import { BButton, BSidebar } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
  },
  methods: {
    linkClicked() {
      this.$router.push({ name: 'model_test', params: { modelId: this.$route.params.modelId, testId: this.task.id } })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/components/variables-dark';

.and-col {
  padding-bottom: 5px;
}

#sidebar-task-handler label {
  font-weight: bold;
}

.test-class {
  background-color: whitesmoke;
}

.dark-layout {
  .test-class {
    background-color: #82868b;
  }
}

.scroll-area-release-entities {
  max-height: 20vh;
}
</style>
