<template>
  <v-select
    v-model="defaultConfig"
    placeholder="-- Select Configuration --"
    label="name"
    :options="configList"
    :value="value"
    @input="onInput"
  >
    <template #option="{ name, version }">
      <div class="d-flex align-items-center">
        <span>{{ name }}</span>
        <span class="font-small-3 text-muted ml-1">Version: {{ version }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'ConfigurationPicker',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      defaultConfig: this.$store.state.configurations.selectedConfiguration ? this.$store.state.configurations.selectedConfiguration : ''
    }
  },
  computed: {
    configList() {
      console.log('Configs', this.$store.state.configurations.configurations)
      return this.$store.state.configurations.configurations
    },
  },
  mounted() {
    this.$store.dispatch('configurations/getConfigurations')
    // if (this.defaultSpec) {
    //   this.$emit('input', this.defaultSpec)
    // }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
