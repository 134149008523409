<template>
  <div>
    <b-modal
      id="import-tests-modal"
      :key="key"
      title="Import Tests from CSV file"
      size="xl"
      no-close-on-backdrop
      hide-footer
      @show="onShow"
      @close="key++"
    >
      <div v-if="loading">
        <div class="modal-body">
          <h1 class="font-medium-3 text-center">
            Loading...
          </h1>
          <h5 class="text-center text-warning">
            <b-spinner />
          </h5>
        </div>
      </div>
      <form-wizard
        v-else
        color="#7367F0"
        :title="null"
        :subtitle="null"
        style="box-shadow: none"
        :finish-button-text="finishButtonText"
        back-button-text="Previous"
        @on-complete="importTests"
      >
        <!-- Step One : Select file -->
        <tab-content
          title="Select File"
          icon="feather icon-file-text"
          :before-change="fileValidator"
        >
          <p class="font-medium-1 mt-0">
            Select CSV file to import
          </p>
          <b-form-file
            v-model="file"
            size="lg"
            accept=".csv"
            invalid-feedback="test"
            :state="Boolean(file)"
            @input="fileLinked()"
          />
        </tab-content>

        <!-- Step Two : Choose the Configuration to import to -->
        <tab-content
          title="Select Configuration"
          icon="feather icon-clipboard"
        >
          <p class="font-medium-1 mt-0">
            Select a Configuration to import into
          </p>
          <ConfigurationPicker v-model="configuration" />
        </tab-content>

         <!-- Step Three : Choose the BT(s) to import to -->
        <tab-content
          title="Behaviour Tree to mapping"
          icon="feather icon-crosshair"
        >
          <p class="font-medium-1 mt-0">
            Select Behaviour Tree(s) to map onto (no selection = "all")
          </p>
          <v-select
            v-model="selected_bts"
            placeholder="-- Select Behaviour Tree(s) --"
            label="name"
            :options="behaviourTrees"
            multiple
          >
            <template #option="{ name, status }">
              <div class="d-flex align-items-center">
                <span>{{ name }}</span>
                <span class="font-small-3 text-muted ml-1">Status: {{ status }}</span>
              </div>
            </template>
          </v-select>
        </tab-content>

        <!-- Step Four : Map CSV columns to Kompozition properties -->
        <tab-content
          title="Column Mapping"
          icon="feather icon-link"
        >
          <div v-if="file">
            <p class="font-medium-2 font-weight-bold">
              Import Preview
            </p>

            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              style="max-height: 60vh"
            >
              <b-table
                :items="tableData"
                :fields="fields"
              />
            </vue-perfect-scrollbar>
          </div>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import store from "@/store";
import { mapState } from 'vuex'
import csv from 'jquery-csv'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ConfigurationPicker from '@/views/Tests/Forms/ConfigurationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from "vue-select";

export default {
  name: 'ImportTestCases',
  components: {
    vSelect,
    ConfigurationPicker,
    FormWizard,
    TabContent,
    VuePerfectScrollbar,
  },
  data() {
    return {
      file: null,
      csv_array: [],
      has_header_row: true,
      configuration: null,
      requirement_fields: [],
      selected_bts: [],
      importLoading: false,
      loading: false,
      fields: [],
      tableData: [],
      key: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapState({
      behaviourTrees: state => state.behaviourTrees.behaviourTrees,
    }),
    tests() {
      const tests = []
      if (this.configuration !== null) {
        this.csv_array.forEach((row, index) => {
          if (this.has_header_row && index === 0) {
            return
          }
          const requirement = {}
          this.fields.forEach(({ property, column }) => {
            requirement[property] = row[column] || null
          })
          tests.push(requirement)
        })
      }
      return tests
    },
    finishButtonText() {
      if (this.importLoading) {
        return 'Importing Tests...'
      }
      return `Import ${this.tests.length} Tests`
    },
  },
  methods: {
    onShow() {
      this.fetchBehaviourTrees()
    },
    fetchBehaviourTrees() {
      this.loading = true
      this.$store
        .dispatch('behaviourTrees/getAllBehaviourTrees')
        .then(() => { this.$store.dispatch('behaviourTrees/getBehaviourTreesListMetadata') })
        .finally(() => {
          this.loading = false
        })
    },
    fileValidator() {
      return new Promise((resolve, reject) => {
        if (this.file) {
          return resolve(true)
        }
        return reject()
      })
    },
    importTests() {
      this.importLoading = true

      const payload = {
        model: this.$route.params.modelId,
        config: this.configuration.id,
        bts: this.selected_bts,
        tests: this.csv_array,
      }
      axiosIns.post('/api/v2/tests/import', payload)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Finished Importing Tests',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$emit('tests-imported')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to import tests',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.importLoading = false
          this.$bvModal.hide('import-tests-modal')
        })
    },
    browserSupportFileUpload() {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    },
    fileLinked() {
      if (!this.browserSupportFileUpload()) {
        alert('The File APIs are not fully supported in this browser!')
      } else {
        const reader = new FileReader()
        console.log(this.file)
        if (this.file) {
          reader.readAsText(this.file)
          reader.onload = evt => {
            this.csv_array = csv.toArrays(evt.target.result)
            if (this.has_header_row && this.csv_array[0]) {
              this.fields = this.csv_array[0].filter(colNm => !this.requirement_fields.includes(colNm.toLowerCase())).map(x => x.toLowerCase())
              this.tableData = this.parseCSV(evt.target.result)
              console.log(this.tableData)
            }
          }
        } else {
          this.csv_array = []
        }
      }
    },
    parseCSV(csvText) {
      const rows = csvText.split('\n');
      if (!rows || rows.length < 2) {
        return [];
      }
      const headers = rows[0].split(',').map(header => header.trim().toLowerCase());
      const data = [];
      for (let i = 1; i < rows.length; i++) {
        const values = rows[i].split(',');
        if (values.length === headers.length) {
          const obj = {};
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = values[j];
          }
          data.push(obj);
        }
      }
      return data;
    },
  },
}
</script>
