<template>
  <div>
    <b-modal
      id="add-test-case-modal"
      :title="`${isNewTest ? 'Create' : 'Update'} Test Case`"
      size="lg"
      @ok="submitFunction"
      @shown="resetForm"
      @closed="onClosed"
    >
      <test-case ref="testCaseForm" :selected-test-case="testLocal" />
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="isNewTest" variant="outline-secondary" @click="cancel()">
          Discard
        </b-button>
        <b-button v-if="isNewTest" variant="success" @click="ok()">
          Create Test Case
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TestCase from '../Test/TestCase.vue'

export default {
  name: 'TestCaseAdd',
  components: {
    BModal,
    TestCase,
  },
  directives: {
    Ripple,
  },
  data() {
    const testLocal = { ref_id: 'new' }
    return { testLocal }
  },
  computed: {
    isNewTest() {
      return !(this.testLocal?.ref_id && this.testLocal.ref_id !== 'new')
    },
  },
  methods: {
    async submitFunction(evt) {
      evt.preventDefault()
      this.testLocal = await this.$refs.testCaseForm.submitFunction()
      this.$bvModal.hide('add-test-case-modal')
      this.$emit('addTest')
      await this.$store.dispatch('tests/fetchTests', { model_id: this.$route.params.modelId })
    },
    resetForm() {
      this.testLocal = { ref_id: 'new' }
    },
    onClosed() {
      this.$emit('addTest')
    },
  },
}
</script>
